import * as React from 'react';
import { MouseEvent, useState } from 'react';
import EyeBallHide from 'assets/images/eyeball-hide.svg';
import EyeBallShow from 'assets/images/eyeball-show.svg';
import Logo from 'assets/images/upwise/theme-logo.svg';
import Arrow from 'assets/images/upwise/right-arrow.svg';
import { TopLogo } from 'styles/auth';
import UpwiseButton from 'components/base/Buttons/UpwiseButton';
import { Themes } from 'types/auth.types';
import CenteredTextLine from 'components/shared/CenteredTextLine/upwise/CenteredTextLine';
import get from 'lodash/get';
import { useCsrf } from 'hooks/useCsrf';
import { useMutation } from '@tanstack/react-query';
import { sendMagicLinkEmail } from 'services/auth';
import { trackHeapEvent } from 'helpers/analytics';
import { captureException } from '@sentry/react';
import { MagicLinkEmailSent } from 'pages/auth-screens/MagicLinkEmailSent/upwise/MagicLinkEmailSent';
import { ISignInPasswordProps } from '../types';
import {
  ErrorMessage,
  EmailGreeting,
  PasswordContainer,
  WarningMessage,
} from '../styles';
import { ForgotPassword } from '../../ForgotPassword';
import { CreateAccountPrompt } from '../../CreateAccountPrompt';
import {
  BackArrowContainer,
  BackArrow,
  ButtonContainer,
  ExpiredTokenContainer,
  LogInContainer,
  PasswordToggle,
  UpwisePasswordInput,
} from './styles';

const SignInPassword = ({
  email,
  error,
  isSubmitting,
  handleSubmit,
  setError,
  setPassword,
  setEmail,
  goBack,
}: ISignInPasswordProps): JSX.Element => {
  const handleBackButton = (
    e: MouseEvent<HTMLImageElement> | MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    setEmail('');
    setError('');
    setPassword('');
    goBack();
  };

  const params = new URLSearchParams(window.location.search);
  const tokenExpired = params.get('token_expired') === 'true';

  const [showPassword, setShowPassword] = useState(false);
  const [capsLockOn, setCapsLockOn] = useState(false);
  const [showMagicLinkSent, setShowMagicLinkSent] = useState(false);

  const { csrfToken } = useCsrf();

  const authWithMagicLink = get(window, 'gon.auth_with_magic_link') || false;
  const { mutateAsync: sendMagicLink } = useMutation(sendMagicLinkEmail);

  const checkCapsEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setCapsLockOn(e.getModifierState('CapsLock'));
  };

  const handleLoginWithoutPassword = async (
    e: MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    if (!!csrfToken && !!email) {
      try {
        await sendMagicLink({ email, authenticityToken: csrfToken });

        setShowMagicLinkSent(true);
        trackHeapEvent('MagicLink:Button:Click', { email });
      } catch (err) {
        captureException(err);
      }
    }
  };

  if (showMagicLinkSent) {
    return (
      <MagicLinkEmailSent
        email={email}
        setError={setError}
        setPassword={setPassword}
        setEmail={setEmail}
        goBack={goBack}
      />
    );
  }

  return (
    <LogInContainer>
      {tokenExpired ? (
        <ExpiredTokenContainer>
          Your one-time link is invalid or has expired.
        </ExpiredTokenContainer>
      ) : (
        <BackArrowContainer onClick={handleBackButton}>
          <BackArrow src={Arrow} />
          Back to entering your email
        </BackArrowContainer>
      )}
      <TopLogo src={Logo} />
      <EmailGreeting isLongEmail={email.length > 35}>
        Hello, {email}!
      </EmailGreeting>
      <WarningMessage>{capsLockOn ? 'Caps Lock is on' : ''}</WarningMessage>

      <PasswordContainer>
        <UpwisePasswordInput
          autoFocus
          autoComplete="current-password"
          id="user_password"
          type={showPassword ? 'text' : 'password'}
          placeholder="Enter your password"
          onChange={(e) => {
            setPassword(e.target.value);
            setError('');
          }}
          onKeyDown={checkCapsEvent}
          onKeyUp={checkCapsEvent}
        />
        <PasswordToggle
          onClick={() => setShowPassword(!showPassword)}
          src={showPassword ? EyeBallHide : EyeBallShow}
        />
      </PasswordContainer>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}

      <ButtonContainer>
        <UpwiseButton
          id="log_in_button"
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          Log in
        </UpwiseButton>
      </ButtonContainer>

      {!authWithMagicLink && (
        <CreateAccountPrompt themeBrandName="Upwise" theme={Themes.UPWISE} />
      )}
      <ForgotPassword email={email} theme={Themes.UPWISE} />

      {authWithMagicLink && (
        <>
          <CenteredTextLine text="or" />
          <ButtonContainer>
            <UpwiseButton
              id="magic_link_button"
              isLoading={isSubmitting}
              onClick={handleLoginWithoutPassword}
              outlined
            >
              Log in without a password
            </UpwiseButton>
          </ButtonContainer>
        </>
      )}
    </LogInContainer>
  );
};

export default SignInPassword;
